<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item label="第三方名称">
          <el-input v-model="departmentname" clearable placeholder="请输入部门名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="operation('add')">新增</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="rolename" label="第三方名称" align="center">
      </el-table-column>
      <el-table-column prop="address" label="地址" align="center">
      </el-table-column>
      <el-table-column prop="personname" label="负责人姓名" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="负责人电话" align="center">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="300" align="center">
        <template slot-scope="scope">
          <el-button @click="operation('update',scope.row)" type="primary">修改</el-button>
          <el-button @click="deleteUser(scope.row.rolecode)" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>


    <el-dialog :title="operationType=='add'?'新增':'修改'" :visible.sync="dialogFormVisible" width="600px" :before-close="onBeforeClose" :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" class="formbox"  label-width="120px">
          <el-form-item label="第三方名称" prop="rolename">
            <el-input v-model="form.rolename" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="地址" prop="address">
            <el-input v-model="form.address" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="负责人姓名"  prop="personname">
            <el-input v-model="form.personname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="负责人电话"  prop="phone">
            <el-input v-model="form.phone" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm('form')">确 定</el-button>
          <el-button @click="resetForm('form')">取 消</el-button>
        </div>
      </el-dialog>


  </div>
</template>

<script>
import { getDepartmentsByName, addDepartment, updateDepartment, deleteDepartment } from '@/api/system';
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
      tableData:[],
      departmentname:'',
      currentPage: 1,
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10
      },
      operationType: 'add',
      form: {
        rolecode:'',
        rolename: '',
        personname: '',
        phone: '',
        address: ''
      },
      dialogFormVisible: false,
      rules:{
        rolename: [
          { required: true, message: '请输入第三方名称', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        personname: [
          { required: true, message: '请输入负责人姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入负责人手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号格式', trigger: 'blur' }
        ],
      }
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getList();
  },
  computed:{},
  methods:{
    //获取部门列表
    getList() {
      getDepartmentsByName({
          userid: this.GLOBAL.adminId(),
          pageSize: this.fenye.pagesize,
          pageNum: this.currentPage,
          rolename: this.departmentname
      }).then(res=>{
        if(res.result == 200){
          let detail = res.detail.rolepage;
          let list = detail.list;
          this.fenye.totalnum = detail.totalRow;
          this.tableData = list;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //搜索
    onSearch(){
      this.currentPage = 1;
      this.getList();
    },
    //新增或编辑
    operation(type,row){
      this.operationType = type;
      this.dialogFormVisible = true;
      if(type == 'update'){
        this.form = {
          rolecode: row.rolecode,
          rolename: row.rolename,
          personname: row.personname,
          phone: row.phone,
          address: row.address,
        }
      }
    },
    //提交新增或编辑
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            userid: this.GLOBAL.adminId(),
            rolename: this.form.rolename,
            personname: this.form.personname,
            phone: this.form.phone,
            address: this.form.address,
          }
          if(this.operationType == 'update'){
            data.rolecode = this.form.rolecode;
            updateDepartment(data).then(res=>{
              if (res.result == 200) {
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
                this.resetForm('form');
                this.currentPage = 1;
                this.getList();
              } else {
                this.$message.error(res.description);
              }
            })
          }
          if(this.operationType == 'add'){
            addDepartment(data).then(res=>{
              if (res.result == 200) {
                this.$message({
                  message: '新增成功',
                  type: 'success'
                });
                this.resetForm('form');
                this.currentPage = 1;
                this.getList();
              } else {
                this.$message.error(res.description);
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].resetFields();
    },
    onBeforeClose(done){
      done();
      this.$refs['form'].resetFields();
    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    
    //删除部门信息
    deleteUser(id){
      this.$confirm('是否确认删除该数据?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: "btn-custom-cancel",
					type: 'warning'
				}).then(() => {
          deleteDepartment({
              userid: this.GLOBAL.adminId(),
              rolecode: id
          }).then(res=>{
            if(res.result == 200){
              this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.currentPage = 1;
							this.getList();
            } else {
              this.$message.error(res.description);
            }
          })
				}).catch(() => {
					
				});
      
    },
  }
}
</script>

<style lang="scss" scoped>
  .paginationbox{
    text-align: right;
    margin-top: 20px;
  }
  .showdatanumber{
    margin: 0 10px 10px;
    color: #409EFF;
    span{
      margin: 0 5px;
      font-size: 20px;
    }
  }
  .searchbox{
    margin: 0 10px;
  }
  .formbox{
    width: 94%;
  }
</style>